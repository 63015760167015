/* global Office */


import {DateTime} from "luxon";

export function showMessage(messageText, type = Office.MailboxEnums.ItemNotificationMessageType.InformationalMessage) {
  const message = {
    type,
    message: messageText,
    icon: "Icon.80x80",
    persistent: true
  };

  console.log(message);

  Office.context.mailbox.item.notificationMessages.replaceAsync("action", message);
}

export function setTimeForDate(date, startTime){
  const newDate = new Date(date);
  newDate.setHours(startTime.getHours(), startTime.getMinutes())
  return newDate;
  // return new Date(date.setHours(startTime.getHours(), startTime.getMinutes()));
}

export function dateDifferenceInMinutes(date, endTime){
  const newStartTime = new Date(date);
  const newEndTime = new Date(date);
  newEndTime.setHours(endTime.getHours(), endTime.getMinutes());
  console.log("newStartTime", newStartTime);
  console.log("newEndTime", newEndTime);
  return Math.floor((newEndTime.getTime() - newStartTime.getTime())/ 60000);

}

export function getStartTime() {
  return new Promise((resolve, reject) => {
    Office.context.mailbox.item.start.getAsync(result => {
      if (result.status === 'succeeded') {
        resolve(result.value);
      } else {
        reject(new Error('Could not fetch start date'));
      }
    })
  })
}

export function getEndTime() {
  return new Promise((resolve, reject) => {
    Office.context.mailbox.item.end.getAsync(result => {
      if (result.status === 'succeeded') {
        resolve(result.value);
      } else {
        reject(new Error('Could not fetch end date'));
      }
    })
  })
}