import webexLogo from "../res/webex_logo.png";
import teamsLogo from "../res/teams_logo.png";
import meetLogo from "../res/meet_logo.png";
import zoomLogo from "../res/zoom_logo.png";

function meetingRegexMatchFunction(regexp, text) {
  const match = regexp.exec(text);

  console.log(match);

  return match?.[1];
}

const meetingTypes = Object.freeze({
  webex: {
    name: "Webex",
    icon: webexLogo,
    hrefFunction: (uri) => `webexteams://meet?sip=${uri}`,
    matchFunction: (url) => url?.includes("ciscopark"),
    regexMatchFunction: () => false,
  },
  "microsoft-teams": {
    name: "Teams",
    icon: teamsLogo,
    hrefFunction: (uri) => uri,
    matchFunction: (uri) => uri?.includes("teams.microsoft.com") || uri?.includes("teams.live.com"),
    regexMatchFunction: (text) => meetingRegexMatchFunction(/(https:.*(?:teams|live)\.microsoft\.com\/.*?)</gm, text),
  },
  zoom: {
    name: "Zoom",
    icon: zoomLogo,
    hrefFunction: (uri) => uri,
    matchFunction: (uri) => uri?.includes("zoom"),
    regexMatchFunction: () => false,
  },
  meet: {
    name: "Meet",
    icon: meetLogo,
    hrefFunction: (uri) => uri,
    matchFunction: (uri) => uri?.includes("meet.google.com"),
    regexMatchFunction: () => false,
  },
});

export function matchMeeting(uri) {
  return Object.keys(meetingTypes).find((key) => meetingTypes[key].matchFunction(uri));
}

export function matchMeetingByRegex(text) {
  for (let [key, data] of Object.entries(meetingTypes)) {
    const match = data.regexMatchFunction(text);
    if (match) {
      return { uri: match, key };
    }
  }

  return null;
}

export default meetingTypes;
