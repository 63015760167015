import { matchMeeting, matchMeetingByRegex } from "./meetingTypes";

function matchAnchorToMeeting(anchors) {
  for (const a of anchors) {
    console.log(a.href);
    const matched = matchMeeting(a.href);

    if (matched) {
      return { uri: a.href, key: matched };
    }
  }

  return null;
}

export function parsePrimaryMeeting(body) {
  console.log(body);

  const bodyItem = document.createElement("div");
  bodyItem.innerHTML = body;

  const anchors = bodyItem.getElementsByTagName("a");

  const primaryMeeting = matchAnchorToMeeting(anchors);

  if (!primaryMeeting) {
    return matchMeetingByRegex(body);
  }

  return primaryMeeting;
}
