import * as React from "react";

import { Spinner } from "@fluentui/react";
import { DetailsList, DetailsListLayoutMode, SelectionMode } from "@fluentui/react/lib/DetailsList";
import { TooltipHost } from "@fluentui/react/lib/Tooltip";

import { Icon } from "@fluentui/react/lib/Icon";

import meetingTypes from "../meetingTypes";

function statusIconData(status) {
  switch (status) {
    case "scheduled":
      return { icon: "DateTime", color: "black" };
    case "finished":
      return { icon: "CheckMark", color: "gray" };
    case "errored":
      return { icon: "Error", color: "red" };
    case "running":
      return { icon: "ProgressRingDots", color: "green" };
  }
}

const columns = [
  {
    key: "id",
    name: "Id",
    data: "string",
    fieldName: "id",
    minWidth: 10,
    maxWidth: 20,
    isResizable: true
  },
  {
    key: "status",
    name: "Status",
    fieldName: "status",
    minWidth: 20,
    maxWidth: 20,
    isResizable: false,
    isCollapsible: true,
    data: "string",
    onRender: (item) => {
      const { icon, color } = statusIconData(item.status);

      return (
        <TooltipHost content={item.status} id={item.status}>
          <Icon iconName={icon} style={{ cursor: "pointer", fontSize: 20, color }} />
        </TooltipHost>
      );
    },
    isPadded: true
  },
  {
    key: "primary",
    name: "Primary",
    fieldName: "primary",
    minWidth: 30,
    maxWidth: 30,
    isResizable: false,
    isCollapsible: true,
    data: "string",
    onRender: (item) => {
      const icon = meetingTypes[item.primaryMeeting?.meetingType]?.icon;

      return icon && <img style={{ width: 20, height: 20 }} src={icon}></img>;
    },
    isPadded: true
  },
  {
    key: "external",
    name: "External",
    fieldName: "external",
    minWidth: 70,
    maxWidth: 90,
    isResizable: true,
    isCollapsible: true,
    data: "string",
    onRender: (item) => {
      return item?.externalMeetings?.map((m) => {
        const icon = meetingTypes[m?.meetingType]?.icon;

        return icon && <img key={m.id} style={{ width: 20, height: 20, padding: 2 }} src={icon}></img>;
      });
    },
    isPadded: true
  }
];

export const OverviewPane = ({ title, meetings }) => {
  return (
    <div className="pane overview-pane">
      <h2>{title}</h2>

      {meetings === null ? (
        <Spinner />
      ) : (
        <>
          <DetailsList
            items={meetings?.map((m) => ({
              key: m.id,
              id: m.id,
              status: m.status,
              primaryMeeting: m.externalMeetings.find((e) => e.isPrimary),
              externalMeetings: m.externalMeetings.filter((e) => !e.isPrimary)
            }))}
            columns={columns}
            selectionMode={SelectionMode.none}
            setKey="none"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
          />
        </>
      )}
    </div>
  );
};
