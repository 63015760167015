/* global Office */

import * as React from "react";

import PropTypes from "prop-types";
import Progress from "./Progress";
import { Button } from "./Button/Button";
import { authenticate, getMeetingByUri, initializeAxiosInterceptor, login } from "../../api";
import { Pivot, PivotItem, TextField, Stack } from "@fluentui/react";
import { SettingsPane } from "../panes/Settings";
import { CreatePane } from "../panes/Create";
import { ActionsPane } from "../panes/Actions";

import { OverviewPane } from "../panes/Overview";

import { parsePrimaryMeeting } from "../parseMeeting";

import "../style.css";

const pivotStyles = {
  root: [
    {
      marginBottom: 10,
    },
  ],
  link: [
    {
      borderRadius: 20,
      padding: "4px 16px",
      height: 24,
      minHeight: 0,
      transition: "0.3s",
      // margin: "0 10px",
      selectors: {
        ":before": {
          borderBottom: "none",
        },
        ":hover": {
          background: "rgb(239 68 68)",
          filter: "brightness(1.2)",
        },
        ":active": {
          background: "rgb(239 68 68)",
          filter: "brightness(1.2)",
        },
      },
    },
  ],
  linkIsSelected: [
    {
      "&.is-selected": {
        color: "white",
        background: "rgb(239 68 68)",
        selectors: {
          ":before": {
            borderBottom: "none",
          },
          ":hover": {
            background: "rgb(239 68 68)",
          },
        },
      },
    },
  ],
};

export default function App({ title, isOfficeInitialized }) {
  console.log("env:", process.env.ENV_NAME);
  console.log("API root:", process.env.REACT_APP_API_ROOT);
  console.log("App url:", process.env.REACT_APP_APP_URL);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [selectedKey, setSelectedKey] = React.useState("actions");

  const [existingMeeting, setExistingMeeting] = React.useState(null);

  React.useEffect(async () => {
    initializeAxiosInterceptor();
    setIsLoggedIn(await authenticate());
  }, [Office?.context]);

  React.useEffect(() => {
    if (!isLoggedIn || selectedKey !== "actions") {
      return;
    }

    Office.context.mailbox.item.body.getAsync("html", { asyncContext: "context" }, async (body) => {
      const primaryMeeting = parsePrimaryMeeting(body?.value);
      if (!primaryMeeting?.uri) {
        return;
      }

      try {
        const res = await getMeetingByUri(primaryMeeting.uri);
        const mData = res?.data;

        setExistingMeeting(mData);
      } catch {
        setExistingMeeting(null);
        console.log("No existing meeting");
      }
    });
  }, [selectedKey, isLoggedIn]);

  if (!isOfficeInitialized) {
    return <Progress title={title} message="Please sideload your addin to see app body." />;
  }

  if (!isLoggedIn) {
    return (
      <div className="ms-welcome">
        <Stack tokens={{ childrenGap: 10 }}>
          <TextField type="text" placeholder="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          <TextField
            type="password"
            placeholder="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            style={{ marginTop: 10 }}
            onClick={async () => {
              try {
                const { token } = (await login({ email, password })).data;

                Office.context.roamingSettings.set("token", token);
                Office.context.roamingSettings.saveAsync();

                setIsLoggedIn(true);
              } catch {
                setIsLoggedIn(false);
              }
            }}
          >
            Log in
          </Button>
        </Stack>
      </div>
    );
  }

  return (
    <div className="ms-welcome">
      <Pivot
        styles={pivotStyles}
        aria-label="App menu"
        linkFormat="tabs"
        selectedKey={selectedKey}
        onLinkClick={(e) => setSelectedKey(e.props.itemKey)}
      >
        <PivotItem headerText={existingMeeting ? "Meeting" : "Actions"} itemKey="actions">
          {existingMeeting ? <OverviewPane meetings={[existingMeeting]} /> : <ActionsPane />}
        </PivotItem>
        <PivotItem headerText="Advanced meeting" itemKey="create">
          <CreatePane onCreate={() => setSelectedKey("meetings")} />
        </PivotItem>
        <PivotItem headerText="Settings" itemKey="settings">
          <SettingsPane />
        </PivotItem>
      </Pivot>
    </div>
  );
}

App.propTypes = {
  title: PropTypes.string,
  isOfficeInitialized: PropTypes.bool,
};
