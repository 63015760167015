import * as React from "react";

import "./button.css";

export function Button({ onClick, style, children }) {
  return (
    <button type="button" className="button" onClick={onClick} style={style}>
      {children}
    </button>
  );
}
