import axios from "axios";

export function initializeAxiosInterceptor() {
  axios.interceptors.request.use((config) => {
    if (typeof Office === "undefined") {
      return config;
    }

    const token = Office.context.roamingSettings.get("token");

    if (!token) {
      return config;
    }
    // eslint-disable-next-line no-param-reassign
    config.headers = config.headers ?? {};

    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = token;

    return config;
  });
}
const apiRoot = process.env.REACT_APP_API_ROOT ?? 'https://qa-api.phasedconnect.com/api/v1';

function apiUrl(url) {
  return `${apiRoot}/${url}`;
}

export async function getMeetings() {
  return axios.get(apiUrl("meetings"));
}

export async function authenticate() {
  try {
    // TODO: update to actual endpoint when possible
    const meetingsResponse = await getMeetings();
    console.log(meetingsResponse);
    return true;
  } catch {
    console.log("Couldn't authenticate. Login required.");
    return false;
  }
}

export function getMeeting(id) {
  return axios.get(apiUrl(`meetings/${id}`));
}

export function getMeetingByUri(uri) {
  return axios.get(apiUrl(`meetings/by-uri/${btoa(uri)}`));
}

export function deleteMeeting(id) {
  return axios.delete(apiUrl(`meetings/${id}`));
}

export function login(loginData) {
  return axios.post(apiUrl("auth/login/jwt"), loginData);
}

export function createMeetings(primaryStream, startTime, duration) {
  const url = apiUrl("meetings");

  if (primaryStream) {
    return axios.post(url, {
      streamServices: {
        [primaryStream.key]: {
          uri: primaryStream.uri,
        },
      },
      startTime: startTime,
      durationMinutes: duration
    });
  }
}
