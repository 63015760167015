/* global Office */

import * as React from "react";

import { Label } from "@fluentui/react/lib/Label";

import meetingTypes from "../meetingTypes";
import { Button } from "../components/Button/Button";
import { createOtherMeetingsForCurrentMeeting } from "../createMeetings";

export const ActionsPane = () => {
  const [meetings, setMeetings] = React.useState(
    Object.fromEntries(Object.keys(meetingTypes).map((m) => [m, Office.context.roamingSettings.get(m)]))
  );

  const onMeetingChange = (e) => {
    setMeetings({ ...meetings, [e.target.id]: e.target.checked });
  };

  const onCreateClick = async () => {
    createOtherMeetingsForCurrentMeeting();
  };

  return (
    <div className="pane overview-pane">
      <Label htmlFor="token">Create other meetings for current meeting</Label>

      <Button onClick={onCreateClick}>Create</Button>
    </div>
  );
};
