/* global Office */

import * as React from "react";

import { Label } from "@fluentui/react/lib/Label";

import { Checkbox, ChoiceGroup, Stack } from "@fluentui/react";
import meetingTypes from "../meetingTypes";
import useRerender from "../hooks/useRerender";

const stackTokens = { childrenGap: 10 };

function SettingsCheckbox({ setting, label, onChange }) {
  return (
    <Checkbox id={setting} checked={Office.context.roamingSettings.get(setting)} label={label} onChange={onChange} />
  );
}

export const SettingsPane = () => {
  const rerender = useRerender();

  const onPlatformsChange = (e) => {
    if (!e.target.checked && e.target.id.includes(Office.context.roamingSettings.get("primary-meeting"))) {
      return;
    }

    Office.context.roamingSettings.set(e.target.id, e.target.checked);
    Office.context.roamingSettings.saveAsync();
    rerender();
  };

  const onPrimaryChange = (e, newPrimary) => {
    Office.context.roamingSettings.set("primary-meeting", newPrimary.key);
    Office.context.roamingSettings.set(`use-${newPrimary.key}`, true);
    Office.context.roamingSettings.saveAsync();
    rerender();
  };

  return (
    <div className="pane settings-pane">
      <Stack tokens={stackTokens}>
        <ChoiceGroup
          defaultSelectedKey={Office.context.roamingSettings.get("primary-meeting")}
          options={Object.entries(meetingTypes).map(([typeKey, typeData]) => ({ key: typeKey, text: typeData.name }))}
          onChange={onPrimaryChange}
          label="Primary Meeting"
        />
      </Stack>

      <Label>Platforms to generate meetings for</Label>
      <Stack tokens={stackTokens}>
        {Object.entries(meetingTypes).map(([typeKey, typeData]) => (
          <SettingsCheckbox
            key={typeKey}
            setting={`use-${typeKey}`}
            label={typeData.name}
            onChange={onPlatformsChange}
          />
        ))}
      </Stack>

      {/* <Label htmlFor="token">Generate other meetings automatically (beta)</Label> */}
      {/* <SettingsCheckbox setting="autogenerate" label="Autogenerate" onChange={onPlatformsChange} /> */}
    </div>
  );
};
